/* Welcome to Compass.
 * In this file you should write your main styles. (or centralize your imports)
 * Import this file using the following HTML or equivalent:
 * <link href="/stylesheets/screen.css" media="screen, projection" rel="stylesheet" type="text/css" /> */
@import "picabo/order";

.form-product-edit {
  .input-color {
    min-width: 220px;
  }
}

#create-products-form {
  .input-multi-select2 {
    display: block;
    margin-bottom: 15px;
  }
}

#edit-product {
  #product-image {
    margin-bottom: 20px;
    width: 100%; 
    height: 100%;
  }
}

.form-inventory-edit {
  .input-quantity {
    width: 60px;
  }
}
